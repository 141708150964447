import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Anchor } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "anchor"
    }}>{`Anchor`}</h1>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<Anchor href=\"https://urbansharing.com\">Link!</Anchor>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Anchor,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Anchor href="https://urbansharing.com" mdxType="Anchor">Link!</Anchor>
    </Playground>
    <h2 {...{
      "id": "without-underline"
    }}>{`Without underline`}</h2>
    <Playground __position={1} __code={'<Anchor href=\"https://urbansharing.com\" textDecoration=\"none\">\n  Link!\n</Anchor>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Anchor,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Anchor href="https://urbansharing.com" textDecoration="none" mdxType="Anchor">
    Link!
  </Anchor>
    </Playground>
    <h2 {...{
      "id": "as-button"
    }}>{`As button`}</h2>
    <Playground __position={2} __code={'<Anchor as=\"button\" href=\"https://urbansharing.com\" textDecoration=\"none\">\n  Button that looks like a link!\n</Anchor>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Anchor,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Anchor as="button" href="https://urbansharing.com" textDecoration="none" mdxType="Anchor">
    Button that looks like a link!
  </Anchor>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Anchor} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      